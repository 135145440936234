<template>
    <div class="settletoday">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="今日结算记录" fixed placeholder safe-area-inset-top />
        <ShowTotal :dataJson='dataJson' />
        <div class="list">
            <div v-if='list.length>0'>
                <div class="item" v-for="(item,index) in list" :key='index' @click="linkDetail(item.deptCode)">
                    <div class="img">
                        <img :src="item.imglink" alt="">
                    </div>
                    <div class="info">
                        <div class="name overnowrap">{{item.deptName}}</div>
                        <div class="time">{{item.creatorTime}}</div>
                    </div>
                    <div class="right">¥{{item.feeMoney > 0 ?Number(item.feeMoney).formatMoney():"0.00"}}<span class="symbol">&#155</span></div>
                </div>
            </div>
            <div v-else class="no-data">
                <img src="../assets/no-data.png" alt="">
            </div>
        </div>
        
    </div>
</template>

<script>
import { button, Toast ,NavBar ,Cell, CellGroup } from 'vant';
import ShowTotal from '@/components/ShowTotal'
export default {
    name:"settletoday",
    data(){
        return{
            dataJson:{
                title:'结算总额',
                total:0,
                frequencyName:"",
                frequencyVal:"",
                numName:"今日结算笔数：",
                numVal:0,
            },
            active:1,
            list:[]
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        linkDetail(deptCode){
            this.$router.push({path:'/settletoday/settletodaydetail',query:{deptCode:deptCode}})
        },
        getToday(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get(`/finance/api/fee/todaySettlementRecord`).then(response => {
                if (response.code === '200') {
                    this.list = response.data
                    // this.dataJson.numVal = response.data.length
                    this.list.forEach(element => {
                        this.dataJson.total = this.$common.float_add(this.dataJson.total,element.feeMoney)
                        this.dataJson.numVal = this.$common.float_add(this.dataJson.numVal,element.count)
                        if(element.feeType == '30060001')  element.imglink = require("../assets/court.png")
                        else if(element.feeType == '30060002')  element.imglink = require("../assets/lawfirm.png")
                        else if(element.feeType == '30060003')  element.imglink = require("../assets/plaintiff.png")
                        else if(element.feeType == '30060004')  element.imglink = require("../assets/service.png")
                        else if(element.feeType == '30060005')  element.imglink = require("../assets/mediate.png")
                        else if(element.feeType == '30060006') element.imglink = require("../assets/signature.png")
                    });
                    Toast.clear();
                }
            })
        },
    },
    mounted() {
        this.getToday()
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [NavBar.name]: NavBar,
        ShowTotal,
    }
}
</script>

<style lang="scss" scoped>
    .settletoday{
        background: #F4F4F4;
        height: 100vh;
        
    }
</style>